import React, {
  CSSProperties,
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
  useMemo,
  useCallback,
  memo,
} from "react";
import {
  Bodies,
  Body,
  Composite,
  Engine,
  Mouse,
  MouseConstraint,
} from "matter-js";
import "./App.css";
import Static from "./static.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Theme = {
  UV: "#FF0000",
  Yellow: "#FFD464",
  DarkYellow: "#CD9D3F",
  White: "#F5F2EA",
  OffWhite: "#F5E7DF",
  Black: "#040404",
  ActualBlack: "#000000",
  Highlight: "#FFA600",
  Gray: "#242017",
  Burn: "#573A00",
  Red: "#EA4439",
};

const Style: { [key: string]: CSSProperties } = {
  VerticalText: {
    writingMode: "vertical-lr",
    fontWeight: "bold",
  },
};

const POLAROID_SIZE = Math.min(400, window.innerHeight / 2);
const SHUTTER_SPEED = 450;

const ShutterContext = createContext({ playShutter: () => {} });
const PolaroidsContext = createContext({ addPolaroid: (type: string) => {} });
const UVContext = createContext({ UV: false, setUV: (arg: boolean) => {} });
const LogContext = createContext({
  log: [] as string[],
  addLog: (msg: string) => {},
});

let mouseX = window.innerWidth / 2;
let mouseY = window.innerHeight / 2;
function useParallax(multiplier: number, ref?: HTMLElement | null) {
  const setParallax = useCallback(
    (x: number, y: number) => {
      if (!ref || multiplier === 0) return;
      ref.style.transition = "transform 0.2s ease-out";
      ref.style.transform = `translateX(${x}px) translateY(${y}px)`;
    },
    [ref, multiplier]
  );

  useEffect(() => {
    function mouseListener(e: { clientX: number; clientY: number }) {
      const x = e.clientX;
      const y = e.clientY;

      mouseX = x;
      mouseY = y;

      const midX = window.innerWidth / 2;
      const midY = window.innerHeight / 2;

      const dim = Math.max(window.innerWidth, window.innerHeight);

      const pX = ((mouseX - midX) * multiplier) / dim;
      const pY = ((mouseY - midY) * multiplier) / dim;

      setParallax(pX, pY);
    }
    mouseListener({
      clientX: mouseX,
      clientY: mouseY,
    });
    window.addEventListener("mousemove", mouseListener);
    return () => window.removeEventListener("mousemove", mouseListener);
  }, [setParallax, multiplier]);
}

const Button = memo(
  ({ text, onPress }: { text: string; onPress: () => void }) => {
    return (
      <p
        className="pressable"
        onClick={onPress}
        style={{
          fontSize: 24,
          fontFamily: "segment",
          fontWeight: "bold",
          color: Theme.White,
          cursor: "pointer",
          backgroundColor: `${Theme.Highlight + "f0"}`,
          padding: 2,
          margin: 2,
          pointerEvents: "initial",
        }}
      >
        {text}
      </p>
    );
  }
);

const TextBG = memo(
  ({
    primary,
    secondary,
    tertiary,
  }: {
    primary: string;
    secondary: string;
    tertiary: string;
  }) => {
    const fontSize = 64;
    const height = primary.length * fontSize;
    const width = fontSize * 1.4;

    const [bgRef, setBgRef] = useState<HTMLDivElement>();
    const [parallaxRef, setParallaxRef] = useState<HTMLDivElement>();

    const [bgDim, setBgDim] = useState({ width: 0, height: 0 });

    const [forceUpdate, setForceUpdate] = useState(0);

    useParallax(-9, parallaxRef);

    useEffect(() => {
      const listener = () => {
        if (bgRef) {
          setBgDim({
            width: bgRef.clientWidth,
            height: bgRef.clientHeight,
          });
        }
      };
      listener();
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [forceUpdate, setForceUpdate, bgRef]);

    const numHorizontal = Math.max(
      1,
      width > 0 ? Math.ceil(bgDim.width / width) : 1
    );
    const numVertical = Math.max(
      1,
      height > 0 ? Math.ceil(bgDim.height / height) : 1
    );

    const animationDelay = 0.5;

    const { UV } = useContext(UVContext);

    return (
      <div
        ref={(ref) => ref && setBgRef(ref)}
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          animation: "bg-focus 1 3s ease-in-out",
          animationDelay: `${animationDelay / 2}s`,
        }}
      >
        <div
          ref={(ref) => ref && setParallaxRef(ref)}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {[...Array(numHorizontal)].map((_, i) => (
            <div
              key={i}
              style={{
                height: "100%",
                width,
                display: "flex",
                opacity: 0.5,
                animation: "slide-left 1 3s cubic-bezier(0.16, 1, 0.3, 1)",
                animationDelay: `${animationDelay}s`,
                animationFillMode: "backwards",
                alignItems: i % 2 === 0 ? "start" : "end",
                justifyContent: "center",
              }}
            >
              {i % 5 === 1 ? (
                <div
                  style={{
                    alignSelf: "start",
                    height: "200%",
                    width: width - 10,
                    marginLeft: 5,
                    marginRight: 5,
                    backgroundImage: `radial-gradient(${
                      UV ? Theme.UV : Theme.DarkYellow
                    } 1px, transparent 0)`,
                    backgroundSize: "8px 8px",
                    animation: `marquee infinite ${UV ? 10 : 30}s linear`,
                    animationDirection: UV ? "reverse" : undefined,
                    overflow: "hidden",
                  }}
                ></div>
              ) : (
                <div
                  key={i}
                  style={{
                    transition: "transform",
                    width,
                    ...(i % 2 === 0
                      ? {
                          animation:
                            "slide-up 1 3s cubic-bezier(0.16, 1, 0.3, 1)",
                          animationDelay: `${animationDelay + i * 0.1}s`,
                          animationFillMode: "backwards",
                        }
                      : {
                          animation:
                            "slide-down 1 3s cubic-bezier(0.16, 1, 0.3, 1)",
                          animationDelay: `${animationDelay + i * 0.1}s`,
                          animationFillMode: "backwards",
                        }),
                  }}
                >
                  {[...Array(numVertical + (i % 2 === 0 ? 1 : 0))].map(
                    (_, j) => (
                      <p
                        key={j}
                        style={{
                          ...Style.VerticalText,
                          fontSize,
                          margin: "auto",
                          marginTop: i % 2 === 0 ? 5 : 25,
                          marginBottom: i % 2 === 0 ? 25 : 5,
                          ...((i * numVertical + j) % 18 === 2
                            ? {
                                transform: "skewY(-2deg)",
                                backgroundColor: UV
                                  ? Theme.UV
                                  : Theme.DarkYellow,
                                color: Theme.White,
                              }
                            : i % 2 === 0
                            ? {
                                color: UV ? Theme.UV : Theme.DarkYellow,
                              }
                            : {
                                color: "transparent",
                                WebkitTextStrokeWidth: 2,
                                WebkitTextStrokeColor: UV
                                  ? Theme.UV
                                  : Theme.DarkYellow,
                              }),
                        }}
                      >
                        {(i * numVertical + j) % 17 === 1
                          ? secondary
                          : (i * numVertical + j) % 13 === 1
                          ? tertiary
                          : primary}
                      </p>
                    )
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
);

const Timer = memo(() => {
  const [mountTime] = useState(Date.now());
  const [time, setTime] = useState(Date.now());

  const { UV } = useContext(UVContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now());
    }, 7);
    return () => clearInterval(interval);
  }, [setTime, UV]);

  const diff = time - mountTime;
  const ms = diff % 1000;
  const s = Math.floor(diff / 1000) % 60;
  const m = Math.floor(diff / 1000 / 60) % 60;
  const h = Math.floor(diff / 1000 / 60 / 60);

  const range = 1000 * 60 * 60 * 24 * 365 * 10;

  return (
    <div>
      <p
        style={{
          fontFamily: "segment",
          fontStyle: "italic",
          fontWeight: "bold",
          fontSize: 16,
          textAlign: "right",
          color: Theme.Highlight,
        }}
      >
        ©piyo.cafe {new Date().getFullYear()}
      </p>
      <p
        style={{
          fontFamily: "segment",
          fontStyle: "italic",
          fontWeight: "bold",
          fontSize: 24,
          textAlign: "right",
          color: Theme.Highlight,
        }}
      >
        {UV
          ? new Date(
              Date.now() + Math.random() * range - range / 2
            ).toDateString()
          : new Date().toDateString()}
      </p>
      <p
        style={{
          fontFamily: "segment-mono",
          fontStyle: "italic",
          fontWeight: "bold",
          fontSize: 32,
          textAlign: "right",
          color: Theme.Highlight,
          textTransform: "full-width",
          letterSpacing: 0,
          marginLeft: 40,
        }}
      >
        {`${h}:${m.toString().padStart(2, "0")}:${s
          .toString()
          .padStart(2, "0")}:${ms.toString().padStart(3, "0")}`}
      </p>
    </div>
  );
});

const Indicator = memo(() => {
  const [height, setHeight] = useState(0.5);
  useEffect(() => {
    function listener(e: MouseEvent) {
      setHeight(e.clientY / window.innerHeight);
    }
    window.addEventListener("mousemove", listener);
    return () => window.removeEventListener("mousemove", listener);
  }, [setHeight]);

  return (
    <FontAwesomeIcon
      icon={["fas", "caret-left"]}
      color={Theme.Highlight}
      style={{
        display: "block",
        position: "absolute",
        right: -20,
        transform: `translateY(calc(${30 - 30 * height}vh - 50%))`,
        transition: "transform 0.2s ease-out",
      }}
    />
  );
});

const Instruments = memo(() => {
  const { UV } = useContext(UVContext);
  const [temp, setTemp] = useState(22);
  const [freq, setFreq] = useState(1886);
  const [interacted, setInteracted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const { log, addLog } = useContext(LogContext);
  const formattedLog = useMemo(() => {
    const _log = log.slice();
    for (let i = 0; i < _log.length - 1; i++) {
      let duplicates = 0;
      while (_log[i + 1] === _log[i]) {
        duplicates++;
        _log.splice(i + 1, 1);
      }
      if (duplicates > 0) {
        _log[i] = `${_log[i]} (x${duplicates + 1})`;
      }
    }
    return _log.map((msg, i) => [msg, i]).reverse();
  }, [log]);

  useEffect(() => {
    function setInstruments() {
      setTemp(!UV ? Math.random() * 0.7 + 21 : Math.random() * 4 - 5);
    }
    setInstruments();
    const interval = setInterval(setInstruments, UV ? 100 : 1000);
    return () => clearInterval(interval);
  }, [UV]);

  const { audioCtx, noise, source, analyser } = useMemo(() => {
    if (!interacted) return {};

    const ctx = new window.AudioContext();

    if (!ctx) {
      return {};
    }

    ctx.suspend();

    const analyser = ctx.createAnalyser();

    const analyseGainNode = ctx.createGain();
    analyseGainNode.gain.value = 0.005;
    analyseGainNode.connect(analyser);

    const gainNode = ctx.createGain();
    gainNode.gain.value = 0.01;
    gainNode.connect(ctx.destination);
    gainNode.connect(analyseGainNode);

    const bufferSize = 6 * ctx.sampleRate;
    const noiseBuffer = ctx.createBuffer(1, bufferSize, ctx.sampleRate);
    const output = noiseBuffer.getChannelData(0);
    for (let i = 0; i < bufferSize; i++) {
      output[i] = Math.random() * 2 - 1;
    }
    const noise = ctx.createBufferSource();
    noise.buffer = noiseBuffer;
    noise.connect(gainNode);
    noise.loop = true;
    noise.start();

    const nggyuNode = ctx.createGain();
    nggyuNode.gain.value = 0.5;
    nggyuNode.connect(ctx.destination);
    nggyuNode.connect(analyser);

    const source = new Audio(require("./nggyu.mp3"));
    const nggyu = ctx.createMediaElementSource(source);
    nggyu.connect(nggyuNode);
    source.loop = true;

    return { audioCtx: ctx, gainNode, noise, nggyu, source, analyser };
  }, [interacted]);

  useEffect(() => {
    if (!audioCtx) return;

    if (isPlaying) {
      audioCtx.resume();
    } else {
      audioCtx.suspend();
    }
  }, [isPlaying, audioCtx]);

  useEffect(() => {
    if (freq === 1894) {
      if (isPlaying && source) {
        source.play();
      }
    } else if (source) {
      source.pause();
      source.currentTime = 0;
    }

    if (!noise) return;
    noise.detune.value = ((freq * 2321273) % 6000) - 6000 / 2;
  }, [noise, freq, isPlaying, source]);

  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement>();
  useEffect(() => {
    if (!canvasRef) return;

    let isRunning = true;
    function drawWaveform() {
      if (!isRunning) return;
      if (!canvasRef) return;

      const ctx = canvasRef?.getContext("2d");
      if (!ctx) return;

      ctx.clearRect(0, 0, canvasRef.width, canvasRef.height);
      ctx.lineWidth = 10;
      ctx.strokeStyle = Theme.Highlight;
      ctx.beginPath();

      if (!analyser || !isPlaying) {
        ctx.moveTo(0, canvasRef.height / 2);
      } else {
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyser.getByteTimeDomainData(dataArray);

        const sliceWidth = (canvasRef.width * 1.0) / bufferLength;
        let x = 0;
        for (let i = 0; i < bufferLength; i++) {
          const v = (dataArray[i] / 128.0 - 0.5) * 3 - 0.5;
          const y = (v * canvasRef.height) / 2;

          if (i === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }

          x += sliceWidth;
        }
      }
      ctx.lineTo(canvasRef.width, canvasRef.height / 2);
      ctx.stroke();

      requestAnimationFrame(drawWaveform);
    }
    drawWaveform();

    return () => {
      isRunning = false;
    };
  }, [canvasRef, analyser, isPlaying]);

  return (
    <div
      style={{
        position: "absolute",
        left: 50,
        top: 45,
        textAlign: "left",
        fontSize: 24,
        fontFamily: "segment-mono",
        fontStyle: "italic",
        fontWeight: "bold",
        color: Theme.Highlight,
      }}
    >
      <p>{temp.toFixed(2)}C</p>
      <p style={{ fontSize: 16 }}>
        FREQ:{freq === 1894 ? "????" : `${(freq / 100).toFixed(2)}Hz`}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", fontStyle: "initial" }}>
          <Button
            text="[<]"
            onPress={() => {
              const newFreq = Math.max(freq - 2, 1870);
              setFreq(newFreq);
              if (newFreq !== freq) {
                addLog(
                  newFreq === 1894
                    ? "????"
                    : `switching to ${(newFreq / 100).toFixed(2)}Hz`
                );
              }
            }}
          />
          <Button
            text={`[${isPlaying ? "pause" : "play"}]`}
            onPress={() => {
              setInteracted(true);
              setIsPlaying(!isPlaying);
              addLog("toggling radio...");
            }}
          />
          <Button
            text="[>]"
            onPress={() => {
              const newFreq = Math.min(freq + 2, 1910);
              setFreq(newFreq);
              if (newFreq !== freq) {
                addLog(
                  newFreq === 1894
                    ? "????"
                    : `switching to ${(newFreq / 100).toFixed(2)}Hz`
                );
              }
            }}
          />
        </div>
        <canvas
          ref={(ref) => ref && setCanvasRef(ref)}
          style={{
            height: 20,
            alignSelf: "stretch",
            borderStyle: "solid",
            borderWidth: 2,
            borderColor: Theme.Highlight,
            margin: 2,
          }}
        />
        {window.innerWidth > window.innerHeight && (
          <div
            style={{
              fontSize: 16,
            }}
          >
            {formattedLog.map(([msg, i]) => (
              <p
                key={i}
                style={{
                  animation: "log-anim 1 0.2s ease-out",
                }}
              >
                {msg}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

const CameraOverlay = memo(() => {
  const borderWidth = 1;
  const baseBorderStyle: CSSProperties = {
    position: "absolute",
    width: "30%",
    height: "30%",
    borderWidth,
    borderColor: Theme.White,
  };

  const [cameraRef, setCameraRef] = useState<HTMLDivElement>();
  useParallax(2, cameraRef);

  const { playShutter } = useContext(ShutterContext);
  const { addPolaroid } = useContext(PolaroidsContext);
  const { UV, setUV } = useContext(UVContext);
  const { addLog } = useContext(LogContext);

  return (
    <div
      ref={(ref) => ref && setCameraRef(ref)}
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        mixBlendMode: "hard-light",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        animation: "unblur-camera 1 1s ease-in-out",
        animationFillMode: "forwards",
        pointerEvents: "none",
        zIndex: 6,
      }}
    >
      <div
        style={{
          ...baseBorderStyle,
          top: 30,
          left: 30,
          borderTopStyle: "solid",
          borderLeftStyle: "solid",
          borderTopLeftRadius: 5,
        }}
      />
      <div
        style={{
          ...baseBorderStyle,
          position: "absolute",
          top: 30,
          right: 30,
          borderTopStyle: "solid",
          borderRightStyle: "solid",
          borderTopRightRadius: 5,
        }}
      />
      <div
        style={{
          ...baseBorderStyle,
          bottom: 30,
          left: 30,
          borderBottomStyle: "solid",
          borderLeftStyle: "solid",
          borderBottomLeftRadius: 5,
        }}
      />
      <div
        style={{
          ...baseBorderStyle,
          bottom: 30,
          right: 30,
          borderBottomStyle: "solid",
          borderRightStyle: "solid",
          borderBottomRightRadius: 5,
        }}
      />
      <div
        style={{
          ...baseBorderStyle,
          right: 30,
          width: undefined,
          height: "30%",
          margin: "0 auto",
          borderRightStyle: "dotted",
          borderColor: Theme.Highlight,
          borderWidth: borderWidth * 2,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            marginRight: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontFamily: "segment",
              fontWeight: "bold",
              color: Theme.Highlight,
              textAlign: "right",
            }}
          >
            +3
          </p>
          <Indicator />
          <p
            style={{
              fontSize: 16,
              fontFamily: "segment",
              fontWeight: "bold",
              color: Theme.Highlight,
              textAlign: "right",
            }}
          >
            -3
          </p>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          {[...Array(21)].map((_, i) => (
            <div
              key={i}
              style={{
                width: i % 5 === 0 ? 8 : 4,
                height: borderWidth * 2,
                backgroundColor: Theme.Highlight,
              }}
            />
          ))}
        </div>
      </div>
      <FontAwesomeIcon
        icon={["fas", "caret-right"]}
        color={Theme.Highlight}
        style={{
          position: "absolute",
          left: 20,
          height: 30,
        }}
      />
      <div
        style={{
          position: "absolute",
          width: 50,
          height: borderWidth,
          backgroundColor: Theme.White,
        }}
      />
      <div
        style={{
          position: "absolute",
          width: borderWidth,
          height: 50,
          backgroundColor: Theme.White,
        }}
      />
      <div
        style={{
          position: "fixed",
          width: 110,
          height: 70,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            width: 20,
            borderStyle: "solid",
            borderRightStyle: "none",
            borderWidth,
            borderColor: Theme.White,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            width: 20,
            borderStyle: "solid",
            borderLeftStyle: "none",
            borderWidth,
            borderColor: Theme.White,
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: 55,
          right: 55,
          display: "flex",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontFamily: "segment",
            fontWeight: "bold",
            color: Theme.Highlight,
            marginRight: 10,
          }}
        >
          REC
        </p>
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 10,
            backgroundColor: Theme.Highlight,
            filter: "blur(1px)",
            WebkitFilter: "blur(1px)",
            animation: `blink ${UV ? "0.4s" : "2s"} infinite ease-in-out`,
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          left: 50,
          right: 50,
          bottom: 40,
          display: "flex",
          flexDirection: "row-reverse",
          flexWrap: "wrap",
          alignItems: "flex-end",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ marginLeft: 8 }}>
          <Timer />
        </div>
        <div style={{ marginRight: -5 }}>
          <p
            style={{
              fontSize: 18,
              fontFamily: "segment",
              fontWeight: "bold",
              color: Theme.Highlight,
              textAlign: "right",
              marginRight: 3,
            }}
          >
            nav menu
          </p>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              text="[show contract]"
              onPress={() => {
                playShutter();
                addPolaroid("suspect");
                addLog("printing...");
              }}
            />
            <Button
              text="[cryptic symbols?]"
              onPress={() => {
                playShutter();
                setTimeout(() => {
                  setUV(!UV);
                  addLog("toggling symbols...");
                }, SHUTTER_SPEED / 2);
              }}
            />
          </div>
        </div>
      </div>
      <Instruments />
    </div>
  );
});

const Polaroid = memo(({ type, body }: { type: string; body: Body }) => {
  const [polaroidRef, setPolaroidRef] = useState<HTMLDivElement | null>();
  useEffect(() => {
    if (!polaroidRef) return;
    let isRunning = true;
    const run = () => {
      if (isRunning) requestAnimationFrame(run);
      polaroidRef.style.top = `${body.position.y}px`;
      polaroidRef.style.left = `${body.position.x}px`;
      polaroidRef.style.transform = `translateX(-50%) translateY(-50%) rotate(${body.angle}rad)`;
    };
    run();
    return () => {
      isRunning = false;
    };
  }, [body, polaroidRef]);

  const [stain, x, y, angle] = useMemo(() => {
    const x = Math.random();
    const y = Math.random();
    const angle = Math.random() * Math.PI * 2;
    switch (Math.floor(Math.random() * 4)) {
      default:
      case 0:
        return [require("./stain-1.png"), x, y, angle];
      case 1:
        return [require("./stain-2.png"), x, y, angle];
      case 2:
        return [require("./stain-3.png"), x, y, angle];
      case 3:
        return [require("./stain-4.png"), x, y, angle];
    }
  }, []);

  return (
    <div
      ref={(ref) => ref && setPolaroidRef(ref)}
      style={{
        position: "fixed",
        width: POLAROID_SIZE,
        height: POLAROID_SIZE,
        backgroundColor: Theme.OffWhite,
        pointerEvents: "initial",
        padding: 10,
        paddingBottom: 10,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={"/me.jpg"}
        alt="Jihoon Yang with face covered by masking tape with X drawn on it"
        style={{
          width: "100%",
          height: "80%",
          objectFit: "cover",
          objectPosition: "top",
          boxShadow: `0 0 5px ${Theme.Burn}a0`,
          filter: "sepia(25%) contrast(110%) brightness(95%)",
          WebkitFilter: "sepia(25%) contrast(110%) brightness(95%)",
          borderRadius: 2,
        }}
      />
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <p
          style={{
            fontFamily: "Kalam",
            fontSize: `${(24 * POLAROID_SIZE) / 500}`,
            fontWeight: "bold",
            color: Theme.Black,
          }}
        >
          Ghost Hunter:{" "}
          <span
            style={{
              backgroundColor: `${Theme.Highlight}c0`,
              color: `${Theme.Black}e0`,
              backgroundBlendMode: "multiply",
              paddingLeft: -2,
              paddingRight: 17,
              marginRight: -19,
              transform: "skewX(-10deg)",
              display: "inline-block",
            }}
          >
            Jihoon Yang
          </span>{" "}
          (양지훈)
        </p>
        <p
          style={{
            fontFamily: "Kalam",
            fontSize: `${(18 * POLAROID_SIZE) / 500}`,
            fontWeight: "bold",
            color: Theme.Black,
            lineHeight: 1,
          }}
        >
          Seen around San Francisco Bay Area lurking in the evenings. Usually found
          developing UI.
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          boxShadow: `inset 0 0 20px ${Theme.Black}a0, inset 0 0 4px ${Theme.Burn}, inset 0 0 40px ${Theme.Burn}10`,
          borderRadius: 2,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <img
          src={stain}
          alt=""
          style={{
            width: 700,
            height: 700,
            position: "absolute",
            top: `${y * 100}%`,
            left: `${x * 100}%`,
            transform: `translateX(-50%) translateY(-50%) rotate(${angle}rad)`,
            opacity: 0.4,
            backgroundBlendMode: "multiply",
          }}
        />
      </div>
    </div>
  );
});

const Polaroids = memo(({ children }: { children: React.ReactNode }) => {
  const { UV } = useContext(UVContext);

  const [count, setCount] = useState(0);
  const [polaroids, setPolaroids] = useState<[number, string, Body][]>([]);
  const [polaroidsRef, setPolaroidsRef] = useState<HTMLDivElement | null>();

  const [grabbed, setGrabbed] = useState(false);
  useEffect(() => {
    const listener = () => {
      setGrabbed(false);
      document.body.style.cursor = "initial";
    };
    window.addEventListener("mouseup", listener);
    return () => window.removeEventListener("mouseup", listener);
  }, [setGrabbed]);

  const engine = useRef(Engine.create()).current;

  useEffect(() => {
    if (!polaroidsRef) return;
    const mouse = Mouse.create(polaroidsRef);
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse,
      //@ts-ignore
      constraint: {
        stiffness: 0.3,
        //@ts-ignore
        angularStiffness: 0.1,
      },
    });
    Composite.add(engine.world, mouseConstraint);
  }, [polaroidsRef, engine]);

  useEffect(() => {
    if (UV) {
      engine.gravity.scale = 0.003;
      const timeout = setTimeout(() => {
        engine.gravity.scale = 0;
      }, 1000);
      return () => {
        clearTimeout(timeout);
        engine.gravity.scale = 0;
      };
    }
  }, [UV, engine]);

  useEffect(() => {
    engine.gravity.scale = 0;

    let isRunning = true;
    let lastFrame = Date.now();
    const run = () => {
      if (isRunning) requestAnimationFrame(run);
      const now = Date.now();

      for (const body of engine.world.bodies) {
        if (body.label === "printing") {
          Body.setPosition(body, {
            x: body.position.x,
            y: window.innerHeight / 2,
          });
          Body.setAngularVelocity(body, 0);
          Body.setAngle(body, 0);
          if (body.position.x < -POLAROID_SIZE / 4) {
            Body.setVelocity(body, { x: 5, y: 0 });
          } else {
            Body.setVelocity(body, { x: 0, y: 0 });
            Body.setStatic(body, false);
            body.label = "printed";
          }
        }
      }

      Engine.update(engine, now - lastFrame);
      lastFrame = now;
    };
    run();
    return () => {
      isRunning = false;
    };
  }, [engine]);

  return (
    <PolaroidsContext.Provider
      value={{
        addPolaroid: (type: string) => {
          const body = Bodies.rectangle(
            -POLAROID_SIZE,
            window.innerHeight / 2,
            POLAROID_SIZE * 1.05,
            POLAROID_SIZE * 1.05,
            {
              label: "printing",
              mass: 100,
            }
          );
          setPolaroids([...polaroids, [count + 1, type, body]]);
          setCount(count + 1);
          Composite.add(engine.world, body);
        },
      }}
    >
      {children}
      <div
        ref={(ref) => ref && setPolaroidsRef(ref)}
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          zIndex: 5,
          cursor: grabbed ? "grabbing" : "grab",
          pointerEvents: grabbed ? "initial" : "none",
        }}
        onMouseDown={(e) => {
          setGrabbed(true);
          document.body.style.cursor = "grabbing";
        }}
      >
        {polaroids.map(([index, type, body]) => (
          <Polaroid key={index} type={type} body={body} />
        ))}
      </div>
    </PolaroidsContext.Provider>
  );
});

const UV = memo(({ children }: { children: React.ReactNode }) => {
  const [UV, setUV] = useState(false);

  const [handRef, setHandRef] = useState<HTMLDivElement>();
  useParallax(-16, handRef);

  const [handL, handR] = useMemo(() => {
    const l = new Image();
    l.src = require("./hand-l.png");
    const r = new Image();
    r.src = require("./hand-r.png");

    return [l, r];
  }, []);

  function Social({ icon, href }: { icon: IconProp; href: string }) {
    return (
      <a
        href={href}
        style={{
          pointerEvents: "initial",
          filter: `drop-shadow(0 0 10px ${Theme.Black}f0)`,
          WebkitFilter: `drop-shadow(0 0 10px ${Theme.Black}f0)`,
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          color={Theme.DarkYellow}
          style={{
            width: "5vh",
            height: "5vh",
            opacity: 0.7,
            verticalAlign: "middle",
            margin: 10,
          }}
        />
      </a>
    );
  }

  return (
    <UVContext.Provider value={{ UV, setUV }}>
      {UV && (
        <div
          ref={(ref) => ref && setHandRef(ref)}
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            zIndex: 0,
            opacity: 0.3,
          }}
        >
          <img
            alt=""
            src={handL.src}
            style={{
              objectFit: "contain",
              position: "absolute",
              height: "100%",
              width: "max(17vw, 17vh)",
              right: "60vw",
              filter:
                "brightness(50%) sepia(100) saturate(100) hue-rotate(10deg)",
              WebkitFilter:
                "brightness(50%) sepia(100) saturate(100) hue-rotate(-10deg)",
            }}
          />
          <img
            alt=""
            src={handR.src}
            style={{
              objectFit: "contain",
              position: "absolute",
              height: "100%",
              width: "max(17vw, 17vh)",
              left: "60vw",
              filter:
                "brightness(50%) sepia(100) saturate(100) hue-rotate(10deg)",
              WebkitFilter:
                "brightness(50%) sepia(100) saturate(100) hue-rotate(-10deg)",
            }}
          />
        </div>
      )}
      {children}
      {UV && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              boxShadow: `inset 0 0 min(8vw, 8vh) ${Theme.Red}, inset 0 0 min(50vw, 50vh) ${Theme.ActualBlack}, inset 0 0 min(50vw, 50vh) ${Theme.ActualBlack}`,
              backgroundBlendMode: "lighten",
              opacity: 0.4,
              pointerEvents: "none",
            }}
          />
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Kalam",
              fontSize: 36,
              color: Theme.White,
              pointerEvents: "none",
            }}
          >
            <Social
              icon={["fas", "envelope"]}
              href="mailto:jihoon.yang@piyo.cafe"
            />
            <Social
              icon={["fas", "file"]}
              href="https://docs.google.com/document/d/1opBOVZC_rQAB8OSCtCLX9PPsWoN3qQ4KJ_0PH1_eFzg/preview"
            />
            <Social icon={["fab", "github"]} href="https://github.com/lanpai" />
            <Social
              icon={["fab", "twitter"]}
              href="https://twitter.com/lanlanlanpai"
            />
            <Social
              icon={["fab", "linkedin-in"]}
              href="https://www.linkedin.com/in/jihoon-yang-piyo/"
            />
          </div>
        </>
      )}
    </UVContext.Provider>
  );
});

const Shutter = memo(({ children }: { children: React.ReactNode }) => {
  const blurRadius = 50;

  const [closed, setClosed] = useState(false);

  return (
    <ShutterContext.Provider
      value={{
        playShutter: () => {
          setClosed(true);
          setTimeout(() => {
            setClosed(false);
          }, SHUTTER_SPEED / 2);
        },
      }}
    >
      {children}
      <div
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          pointerEvents: "none",
          transform: "rotate(-5deg)",
          zIndex: 5,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: `calc(-${blurRadius * 2}px - 100vh)`,
            bottom: closed ? `calc(50vh - ${blurRadius}px)` : "110vh",
            left: `calc(-${blurRadius * 2}px - 100vh)`,
            right: `calc(-${blurRadius * 2}px - 100vh)`,
            backgroundColor: Theme.ActualBlack,
            transition: "bottom 0.05s ease-out",
            boxShadow: `0 0 ${blurRadius}px ${Theme.ActualBlack}`,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: closed ? `calc(50vh - ${blurRadius}px)` : "110vh",
            bottom: `calc(-${blurRadius * 2}px - 100vh)`,
            left: `calc(-${blurRadius * 2}px - 100vh)`,
            right: `calc(-${blurRadius * 2}px - 100vh)`,
            backgroundColor: Theme.ActualBlack,
            transition: "top 0.05s ease-out",
            boxShadow: `0 0 ${blurRadius}px ${Theme.ActualBlack}`,
          }}
        />
      </div>
    </ShutterContext.Provider>
  );
});

const Log = memo(({ children }: { children: React.ReactNode }) => {
  const [log, setLog] = useState<string[]>(["initializing..."]);

  const addLog = useCallback(
    (msg: string) => {
      setLog([...log, msg]);
    },
    [log, setLog]
  );

  return (
    <LogContext.Provider value={{ log, addLog }}>
      {children}
    </LogContext.Provider>
  );
});

function App() {
  return (
    <div
      className="App"
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        backgroundColor: Theme.Black,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Log>
        <UV>
          <Shutter>
            <Polaroids>
              <TextBG
                primary="梁智薰"
                secondary="(o_ _)ﾉ彡☆"
                tertiary="양지훈"
              />
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundImage: `url(${Static})`,
                  backgroundSize: "100px 100px",
                  imageRendering: "crisp-edges",
                  animation: "static-idle infinite 5s linear",
                  opacity: 0.1,
                  mixBlendMode: "multiply",
                  pointerEvents: "none",
                  zIndex: 5,
                }}
              />
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  boxShadow:
                    "inset 0 0 min(50vw, 50vh) black, inset 0 0 min(50vw, 50vh) black, inset 0 0 min(50vw, 50vh) black",
                  zIndex: 5,
                  pointerEvents: "none",
                }}
              />
              <CameraOverlay />
            </Polaroids>
          </Shutter>
        </UV>
      </Log>
    </div>
  );
}

export default App;
